import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import { MODAL_ROOT_ID } from "./lib";

import Internationalization from "./Components/Internationalization";

ReactDOM.render(
  <Internationalization />,
  document.getElementById(MODAL_ROOT_ID)
);
