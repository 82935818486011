import { useEffect } from "react";

import { useLanguage } from "../lib";

export default function Internationalization() {
  const i18n = useLanguage();

  useEffect(() => {
    window.i18n = i18n;

    return () => {
      delete window.i18n;
    };
  }, []);

  return null;
}
